import React from "react";

function Index() {
    return (
        <>
            <div className="td_preloader">
                <div className="td_preloader_in">
                    <span></span>
                    <span></span>
                </div>
            </div>
            <header className="td_site_header td_style_1 td_type_1 td_sticky_header td_medium td_heading_color">
                <div className="td_main_header">
                    <div className="container">
                        <div className="td_main_header_in">
                            <div className="td_main_header_left">
                                <div className="td_site_branding" href="index.html">
                                    <img src="assets/img/home_4/logo/young-leaners.png" alt="Logo" width={150} height={200} />
                                </div>
                            </div>
                            <div className="td_main_header_right">
                                <nav className="td_nav">
                                    <div className="td_nav_list_wrap">
                                        <div className="td_nav_list_wrap_in">
                                            <ul className="td_nav_list">
                                                <li className="menu-item-has-children">
                                                    <a href="#">Why Young learners
                                                    </a>
                                                </li>
                                                <li className="menu-item-has-children">
                                                    <a href="#">Programs
                                                    </a>
                                                </li>
                                                <li className="menu-item-has-children">
                                                    <a>Our Approach
                                                    </a>
                                                </li>
                                                <li><a href="#">Carrers</a></li>
                                                <li><a href="#">Resource</a></li>
                                                <li className="menu-item-has-children">
                                                    <a href="#">Contact</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>

                                <div className="td_hero_toolbox_wrap">
                                    <a className="td_btn td_style_1 td_radius_30 td_medium">
                                        <span className="td_btn_in td_white_color td_accent_bg">
                                            <span>Get A Tour</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <section className="td_hero td_style_4 td_center text-center td_hobble">
                <div className="container">
                    <div className="td_hero_text wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                        <p className="td_hero_subtitle_up td_accent_color text-uppercase td_semibold td_fs_18 td_mb_10 td_opacity_9 td_spacing_1">KINDERGARDEN & BABY CARE</p>
                        <h1 className="td_hero_title td_fs_64 td_mb_20">We Prepare Your Child For Lifetime</h1>
                        <p className="td_hero_subtitle td_fs_18 td_heading_color text-capitalize td_mb_40">We denounce with righteous indignation and dislike men who are so beguiled and demoralized</p>
                        <div className="td_btns_group">
                            <a href="#" className="td_btn td_style_1 td_radius_30 td_medium">
                                <span className="td_btn_in td_white_color td_accent_bg">
                                    <span>Find Courses</span>
                                    <svg strokestrokestrokewidth="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.1575 4.34302L3.84375 15.6567" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15.157 11.4142C15.157 11.4142 16.0887 5.2748 15.157 4.34311C14.2253 3.41142 8.08594 4.34314 8.08594 4.34314" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                            </a>
                            <div className="td_avatars_wrap">
                                <div className="td_avatars">
                                    <div><img src="assets/img/home_4/avatar_1.png" alt="" /></div>
                                    <div><img src="assets/img/home_4//avatar_2.png" alt="" /></div>
                                    <div><img src="assets/img/home_4/avatar_3.png" alt="" /></div>
                                    <div>
                                        <img src="assets/img/home_4/avatar_3.png" alt="" />
                                        <i className="fa-solid fa-plus"></i>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="mb-0 td_fs_20 td_semibold">2k Students</h3>
                                    <p className="mb-0 td_fs_18 td_opacity_6">Join Our Online class</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="td_hero_img_box_left">
                    <div className="td_hero_img_1 position-absolute wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.35s">
                        <div className="td_hero_img_in">
                            <img src="assets/img/home_4/hero_img_1.png" alt="" />
                        </div>
                    </div>
                    <div className="td_hero_img_2 position-absolute wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.35s">
                        <div className="td_hero_img_in">
                            <img src="assets/img/home_4/hero_img_2.png" alt="" />
                        </div>
                    </div>
                    <div className="td_hero_shape_1 position-absolute td_hover_layer_5">
                        <img src="assets/img/home_4/hero_shape_1.svg" alt="" />
                    </div>
                    <div className="td_hero_shape_2 position-absolute td_hover_layer_3">
                        <img src="assets/img/home_4/hero_shape_2.svg" alt="" />
                    </div>
                    <div className="td_hero_shape_5 position-absolute">
                        <img src="assets/img/home_4/hero_shape_5.svg" alt="" />
                    </div>
                </div>
                <div className="td_hero_img_box_right">
                    <div className="td_hero_img_3 position-absolute wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.35s">
                        <div className="td_hero_img_in">
                            <img src="assets/img/home_4/hero_img_3.png" alt="" />
                        </div>
                    </div>
                    <div className="td_hero_img_4 position-absolute wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.35s">
                        <div className="td_hero_img_in">
                            <img src="assets/img/home_4/hero_img_4.png" alt="" />
                        </div>
                    </div>
                    <div className="td_hero_shape_3 position-absolute td_hover_layer_5">
                        <img src="assets/img/home_4/hero_shape_3.svg" alt="" />
                    </div>
                    <div className="td_hero_shape_4 position-absolute td_hover_layer_3">
                        <img src="assets/img/home_4/hero_shape_4.svg" alt="" />
                    </div>
                </div>
                <div className="td_hero_shape_6 position-absolute td_hover_layer_3">
                    <img src="assets/img/home_4/hero_shape_6.svg" alt="" />
                </div>
                <div className="td_hero_shape_7 position-absolute">
                    <img src="assets/img/home_4/hero_shape_7.svg" alt="" />
                </div>
                <div className="td_hero_shape_8 position-absolute td_hover_layer_3">
                    <img src="assets/img/home_4/hero_shape_8.svg" alt="" />
                </div>
                <div className="td_hero_shape_9 position-absolute">
                    <img src="assets/img/home_4/hero_shape_9.svg" alt="" />
                </div>
                <div className="td_hero_shape_10 position-absolute td_hover_layer_5">
                    <img src="assets/img/home_4/hero_shape_10.svg" alt="" />
                </div>
            </section>

            <section className="td_shape_section_2">
                <div className="td_shape td_shape_position_1 td_accent_color">
                    <svg xmlns="http://www.w3.org/2000/svg" strokestrokewidth="51" height="37" viewBox="0 0 51 37" fill="none">
                        <path opacity="0.51" d="M1.76764 36.4103L25.5 1.81671L49.2324 36.4103H1.76764Z" stroke="currentColor" />
                    </svg>
                </div>
                <div className="td_shape td_shape_position_2">
                    <img src="assets/img/home_4/about_shape_2.svg" alt="" />
                </div>
                <div className="td_shape td_shape_position_3">
                    <img src="assets/img/home_4/about_shape_3.svg" alt="" />
                </div>
                <div className="td_shape td_shape_position_4">
                    <img src="assets/img/home_4/about_shape_4.svg" alt="" />
                </div>
                <div className="td_shape td_shape_position_5"></div>
                <div className="td_height_120 td_height_lg_80"></div>
                <div className="container">
                    <div className="row align-items-center td_gap_y_40">
                        <div className="col-xl-6">
                            <div className="td_image_box td_style_5">
                                <div className="td_image_box_img_1 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
                                    <img src="assets/img/home_4/about_img_1.png" alt="" />
                                </div>
                                <div className="td_image_box_img_2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s">
                                    <img src="assets/img/home_4/about_img_2.png" alt="" />
                                </div>
                                <div className="td_image_box_circle wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                                    <div className="td_image_box_circle_in td_center">
                                        <img src="assets/img/home_4/about_img_circle_text_icon.svg" alt="" className="td_image_box_circle_icon" />
                                        <img src="assets/img/home_4/about_img_circle_text.svg" alt="" className="td_image_box_circle_text" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.4s">
                            <div className="td_section_heading td_style_1 td_mb_30">
                                <p className="td_section_subtitle_up td_fs_18 td_semibold td_spacing_1 td_mb_10 text-uppercase td_accent_color">About us</p>
                                <h2 className="td_section_title td_fs_48 td_mb_30">We are Always Best Course For your Learning </h2>
                                <p className="td_section_subtitle td_fs_18 mb-0">Far far away, behind the word mountains, far from the Consonantia, there live the blind texts. Separated they marks grove right at the coast of the Semantics a large language ocean</p>
                            </div>
                            <div className="td_mb_40">
                                <ul className="td_list td_style_4 td_mp_0">
                                    <li>
                                        <div className="td_list_icon td_center">
                                            <div className="td_list_icon_icon_in td_center">
                                                <img src="assets/img/home_4/about_icon_1.svg" alt="" />
                                            </div>
                                        </div>
                                        <div className="td_list_right">
                                            <h3 className="td_fs_20 td_semibold td_mb_2">Kids Care Room</h3>
                                            <p className="mb-0 td_fs_14 td_heading_color">Dislike men who are so and demoralized</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="td_list_icon td_center">
                                            <div className="td_list_icon_icon_in td_center">
                                                <img src="assets/img/home_4/about_icon_2.svg" alt="" />
                                            </div>
                                        </div>
                                        <div className="td_list_right">
                                            <h3 className="td_fs_20 td_semibold td_mb_2">Outdoor Games</h3>
                                            <p className="mb-0 td_fs_14 td_heading_color">Dislike men who are so and demoralized</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="td_list_icon td_center">
                                            <div className="td_list_icon_icon_in td_center">
                                                <img src="assets/img/home_4/about_icon_3.svg" alt="" />
                                            </div>
                                        </div>
                                        <div className="td_list_right">
                                            <h3 className="td_fs_20 td_semibold td_mb_2">Best Mentors</h3>
                                            <p className="mb-0 td_fs_14 td_heading_color">Dislike men who are so and demoralized</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="td_list_icon td_center">
                                            <div className="td_list_icon_icon_in td_center">
                                                <img src="assets/img/home_4/about_icon_1.svg" alt="" />
                                            </div>
                                        </div>
                                        <div className="td_list_right">
                                            <h3 className="td_fs_20 td_semibold td_mb_2">Transportations</h3>
                                            <p className="mb-0 td_fs_14 td_heading_color">Dislike men who are so and demoralized</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <a href="#" className="td_btn td_style_1 td_radius_10 td_medium">
                                <span className="td_btn_in td_white_color td_accent_bg">
                                    <span>More About </span>
                                    <svg strokestrokewidth="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.1575 4.34302L3.84375 15.6567" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M15.157 11.4142C15.157 11.4142 16.0887 5.2748 15.157 4.34311C14.2253 3.41142 8.08594 4.34314 8.08594 4.34314" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="td_height_120 td_height_lg_80"></div>
            </section>

            <section className="td_gray_bg_7 td_shape_section_3">
                <div className="td_shape td_shape_position_1">
                    <img src="assets/img/home_4/course_shape_1.svg" alt="" />
                </div>
                <div className="td_shape td_shape_position_2">
                    <img src="assets/img/home_4/course_shape_2.svg" alt="" />
                </div>
                <div className="td_shape td_shape_position_3">
                    <img src="assets/img/home_4/course_shape_3.svg" alt="" />
                </div>
                <div className="td_shape td_shape_position_4"></div>
                <div className="td_height_112 td_height_lg_75"></div>
                <div className="container">
                    <div className="td_section_heading td_style_1 text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                        <p className="td_section_subtitle_up td_fs_18 td_semibold td_spacing_1 td_mb_10 text-uppercase td_accent_color">Popular Courses</p>
                        <h2 className="td_section_title td_fs_48 mb-0">Academic Courses</h2>
                    </div>
                    <div className="td_height_50 td_height_lg_50"></div>
                    <div className="row td_gap_y_30">
                        <div className="col-lg-4 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.25s">
                            <div className="td_card td_style_3 td_type_1 d-block td_radius_10">
                                <a href="#" className="td_card_thumb">
                                    <img src="assets/img/home_4/course_thumb_1.jpg" alt="" />
                                </a>
                                <div className="td_card_info">
                                    <div className="td_card_info_in">
                                        <a href="#" className="td_card_category td_fs_14 td_semibold td_heading_color td_mb_14"><span>Pre-School</span></a>
                                        <h2 className="td_card_title td_fs_24 td_mb_16"><a href="#">Starting Reputed Education & Build your Skills</a></h2>
                                        <p className="td_card_subtitle td_heading_color td_opacity_7 td_mb_20">Far far away, behind the word mountains, far from the Consonantia.</p>
                                        <ul className="td_card_meta td_mp_0 td_fs_18 td_medium td_heading_color">
                                            <li>
                                                <img src="assets/img/icons/user_3.svg" alt="" />
                                                <span className="td_opacity_7">150 Seats</span>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/book.svg" alt="" />
                                                <span className="td_opacity_7">12 Semesters</span>
                                            </li>
                                        </ul>
                                        <div className="td_card_btn">
                                            <a href="#" className="td_btn td_style_1 td_radius_30 td_medium">
                                                <span className="td_btn_in td_white_color td_accent_bg">
                                                    <span>Enroll Now</span>
                                                    <svg strokestrokewidth="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.1575 4.34302L3.84375 15.6567" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M15.157 11.4142C15.157 11.4142 16.0887 5.2748 15.157 4.34311C14.2253 3.41142 8.08594 4.34314 8.08594 4.34314" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                            <div className="td_card td_style_3 td_type_1 d-block td_radius_10">
                                <a href="#" className="td_card_thumb">
                                    <img src="assets/img/home_4/course_thumb_2.jpg" alt="" />
                                </a>
                                <div className="td_card_info">
                                    <div className="td_card_info_in">
                                        <a href="#" className="td_card_category td_fs_14 td_semibold td_heading_color td_mb_14"><span>DayCare</span></a>
                                        <h2 className="td_card_title td_fs_24 td_mb_16"><a href="#">Master Technology & Elevate Your Career lavelup</a></h2>
                                        <p className="td_card_subtitle td_heading_color td_opacity_7 td_mb_20">Far far away, behind the word mountains, far from the Consonantia.</p>
                                        <ul className="td_card_meta td_mp_0 td_fs_18 td_medium td_heading_color">
                                            <li>
                                                <img src="assets/img/icons/user_3.svg" alt="" />
                                                <span className="td_opacity_7">90 Seats</span>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/book.svg" alt="" />
                                                <span className="td_opacity_7">08 Semesters</span>
                                            </li>
                                        </ul>
                                        <div className="td_card_btn">
                                            <a href="#" className="td_btn td_style_1 td_radius_30 td_medium">
                                                <span className="td_btn_in td_white_color td_accent_bg">
                                                    <span>Enroll Now</span>
                                                    <svg strokestrokewidth="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.1575 4.34302L3.84375 15.6567" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M15.157 11.4142C15.157 11.4142 16.0887 5.2748 15.157 4.34311C14.2253 3.41142 8.08594 4.34314 8.08594 4.34314" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.35s">
                            <div className="td_card td_style_3 td_type_1 d-block td_radius_10">
                                <a href="#" className="td_card_thumb">
                                    <img src="assets/img/home_4/course_thumb_3.jpg" alt="" />
                                </a>
                                <div className="td_card_info">
                                    <div className="td_card_info_in">
                                        <a href="#" className="td_card_category td_fs_14 td_semibold td_heading_color td_mb_14"><span>Junior Nursery</span></a>
                                        <h2 className="td_card_title td_fs_24 td_mb_16"><a href="#">Boost Creativity & Expand Your Horizons your Skills</a></h2>
                                        <p className="td_card_subtitle td_heading_color td_opacity_7 td_mb_20">Far far away, behind the word mountains, far from the Consonantia.</p>
                                        <ul className="td_card_meta td_mp_0 td_fs_18 td_medium td_heading_color">
                                            <li>
                                                <img src="assets/img/icons/user_3.svg" alt="" />
                                                <span className="td_opacity_7">200 Seats</span>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/book.svg" alt="" />
                                                <span className="td_opacity_7">12 Semesters</span>
                                            </li>
                                        </ul>
                                        <div className="td_card_btn">
                                            <a href="#" className="td_btn td_style_1 td_radius_30 td_medium">
                                                <span className="td_btn_in td_white_color td_accent_bg">
                                                    <span>Enroll Now</span>
                                                    <svg strokestrokewidth="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.1575 4.34302L3.84375 15.6567" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M15.157 11.4142C15.157 11.4142 16.0887 5.2748 15.157 4.34311C14.2253 3.41142 8.08594 4.34314 8.08594 4.34314" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="td_height_50 td_height_lg_40"></div>
                    <div className="text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                        <a href="#" className="td_btn td_style_3 td_medium td_heading_color td_fs_18">
                            <span>See all Courses</span>
                            <i>
                                <svg strokestrokewidth="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 6L1 6" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M12 11C12 11 17 7.31756 17 5.99996C17 4.68237 12 1 12 1" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </i>
                        </a>
                    </div>
                </div>
                <div className="td_height_120 td_height_lg_80"></div>
            </section>

            <div className="td_gray_bg_5 td_shape_section_4 td_hobble">
                <div className="td_shape td_shape_position_1 td_hover_layer_5">
                    <img src="assets/img/home_4/faq_shape_5.svg" alt="" />
                </div>
                <div className="td_shape td_shape_position_2">
                    <img src="assets/img/home_4/faq_shape_6.svg" alt="" />
                </div>
                <div className="td_shape td_shape_position_3 td_hover_layer_3">
                    <img src="assets/img/home_4/faq_shape_7.svg" alt="" />
                </div>
                <div className="td_shape td_shape_position_4">
                    <img src="assets/img/home_4/faq_shape_5.svg" alt="" />
                </div>
                <div className="td_height_112 td_height_lg_75"></div>
                <div className="container">
                    <div className="row align-items-center td_gap_y_40">
                        <div className="col-xl-6 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
                            <div className="td_section_heading td_style_1">
                                <p className="td_section_subtitle_up td_fs_18 td_semibold td_spacing_1 td_mb_10 text-uppercase td_accent_color">
                                    FREQUENTLY ASKED QUESTIONS
                                </p>
                                <h2 className="td_section_title td_fs_48 mb-0">We are The Best Kids School in City</h2>
                            </div>
                            <div className="td_height_50 td_height_lg_50"></div>
                            <div className="td_accordians td_style_1 td_type_1">
                                <div className="td_accordian active">
                                    <div className="td_accordian_head">
                                        <h2 className="td_accordian_title td_fs_20 td_medium">How age Of Pre-School?</h2>
                                        <span className="td_accordian_toggle">
                                            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.355 9L7 3.43725L1.645 9L0 7.28745L7 -9.53674e-07L14 7.28745L12.355 9Z" fill="white" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="td_accordian_body">
                                        <p>
                                            AIndignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble
                                        </p>
                                    </div>
                                </div>
                                <div className="td_accordian">
                                    <div className="td_accordian_head">
                                        <h2 className="td_accordian_title td_fs_20 td_medium">How can Admit Nursery For Child Care baby?</h2>
                                        <span className="td_accordian_toggle">
                                            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.355 9L7 3.43725L1.645 9L0 7.28745L7 -9.53674e-07L14 7.28745L12.355 9Z" fill="white" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="td_accordian_body">
                                        <p>
                                            AIndignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble
                                        </p>
                                    </div>
                                </div>
                                <div className="td_accordian">
                                    <div className="td_accordian_head">
                                        <h2 className="td_accordian_title td_fs_20 td_medium">How can Admit Nursery For Child Care baby?</h2>
                                        <span className="td_accordian_toggle">
                                            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.355 9L7 3.43725L1.645 9L0 7.28745L7 -9.53674e-07L14 7.28745L12.355 9Z" fill="white" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="td_accordian_body">
                                        <p>
                                            AIndignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble
                                        </p>
                                    </div>
                                </div>
                                <div className="td_accordian">
                                    <div className="td_accordian_head">
                                        <h2 className="td_accordian_title td_fs_20 td_medium">How can Admit Nursery For Child Care baby?</h2>
                                        <span className="td_accordian_toggle">
                                            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.355 9L7 3.43725L1.645 9L0 7.28745L7 -9.53674e-07L14 7.28745L12.355 9Z" fill="white" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="td_accordian_body">
                                        <p>
                                            AIndignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.4s">
                            <div className="td_image_box td_style_9">
                                <div className="td_image_box_img_1">
                                    <img src="assets/img/home_4/faq_img.png" alt="" />
                                </div>
                                <div className="td_image_box_shape_2 position-absolute td_hover_layer_3">
                                    <img src="assets/img/home_4/faq_shape_1.svg" alt="" />
                                </div>
                                <div className="td_image_box_shape_3 position-absolute td_hover_layer_5">
                                    <img src="assets/img/home_4/faq_shape_2.svg" alt="" />
                                </div>
                                <div className="td_image_box_shape_4 position-absolute td_hover_layer_3">
                                    <img src="assets/img/home_4/faq_shape_3.svg" alt="" />
                                </div>
                                <div className="td_image_box_shape_5 position-absolute">
                                    <img src="assets/img/home_4/faq_shape_4.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="td_height_120 td_height_lg_80"></div>
            </div>

            <section>
                <div className="td_height_120 td_height_lg_0"></div>
                <div className="td_testimonial_with_shape_wrap">
                    <div className="td_testimonial_with_shape td_hobble" style={{ backgroundImage: 'url(assets/img/home_4/testimonial_bg.png)' }}>
                        <div className="td_testimonial_shape_1 td_accent_color position-absolute td_hover_layer_3">
                            <svg width="46" height="240" viewBox="0 0 46 240" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M44.61 1.48954C43.4975 0.625824 41.8935 0.827182 41.0441 1.95066C15.4219 35.8376 1.2421 77.0249 0.598928 119.547C-0.0442428 162.07 12.8834 203.667 37.469 238.314C38.2841 239.462 39.8812 239.712 41.0193 238.882C42.1575 238.053 42.4057 236.458 41.591 235.309C17.6568 201.557 5.0724 161.041 5.69884 119.625C6.32528 78.2082 20.1293 38.0916 45.0733 5.07867C45.9224 3.95492 45.7226 2.35325 44.61 1.48954Z" fill="currentColor" />
                            </svg>
                        </div>
                        <div className="td_testimonial_shape_3 td_accent_color position-absolute td_hover_layer_3">
                            <svg width="37" height="28" viewBox="0 0 37 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.55" d="M18.8333 0L23.0617 10.3647H36.7449L25.6749 16.7705L29.9033 27.1353L18.8333 20.7295L7.76338 27.1353L11.9917 16.7705L0.921768 10.3647H14.605L18.8333 0Z" fill="currentColor" />
                            </svg>
                        </div>
                        <div className="td_testimonial_shape_4 td_accent_color position-absolute td_hover_layer_5">
                            <svg width="40" height="344" viewBox="0 0 40 344" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38.0243 1C38.0243 1 -2.76813 40.5632 1.28292 70.4758C4.53272 94.4719 32.5988 98.8059 38.0243 122.471C46.2303 158.263 -0.0606009 173.325 1.28292 209.875C2.51911 243.506 43.8038 255.981 38.0243 289.212C33.7698 313.675 1.28292 343 1.28292 343" stroke="currentColor" />
                            </svg>
                        </div>
                        <div className="td_height_120 td_height_lg_80"></div>
                        <div className="td_slider td_style_1">
                            <div className="container">
                                <div className="row align-items-center td_gap_y_40">
                                    <div className="col-lg-7 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                                        <div className="td_image_box td_style_8">
                                            <img src="assets/img/home_4/testimonial_img_1.png" alt="" className="td_image_box_img_1" />
                                            <img src="assets/img/home_4/testimonial_img_2.png" alt="" className="td_image_box_img_2 position-absolute" />
                                            <img src="assets/img/home_4/testimonial_img_3.png" alt="" className="td_image_box_img_3 position-absolute" />
                                            <div className="td_image_box_shape_1 td_accent_color position-absolute">
                                                <img src="assets/img/home_4/testimonial_shape_2.svg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s">
                                        <div className="td_section_heading td_style_1">
                                            <p className="td_section_subtitle_up td_fs_18 td_semibold td_spacing_1 td_mb_10 text-uppercase td_accent_color">Testimonials</p>
                                            <h2 className="td_section_title td_fs_48 mb-0">Parents Speech With Us</h2>
                                        </div>
                                        <div className="td_height_50 td_height_lg_50"></div>
                                        <div className="td_slider_container" data-autoplay="0" data-loop="1" data-speed="800" data-center="0" data-variable-width="0" data-slides-per-view="1">
                                            <div className="td_slider_wrapper">
                                                <div className="td_slide">
                                                    <div className="td_testimonial td_style_1 td_type_3">
                                                        <div className="td_rating td_mb_35" data-rating="5">
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-regular fa-star"></i>
                                                            <div className="td_rating_percentage">
                                                                <i className="fa-solid fa-star fa-fw"></i>
                                                                <i className="fa-solid fa-star fa-fw"></i>
                                                                <i className="fa-solid fa-star fa-fw"></i>
                                                                <i className="fa-solid fa-star fa-fw"></i>
                                                                <i className="fa-solid fa-star fa-fw"></i>
                                                            </div>
                                                        </div>
                                                        <blockquote className="td_testimonial_text td_fs_24 td_medium td_heading_color td_mb_35 td_opacity_9">
                                                            The pandemic has accelerated the shift to online and hybrid learning models. Platforms like Coursera, edX, and university-specific online programs, accelerated the shift to online and hybrid.
                                                        </blockquote>
                                                        <div className="td_testimonial_meta">
                                                            <img src="assets/img/home_4/avatar_1.png" alt="" />
                                                            <div className="td_testimonial_meta_right">
                                                                <h3 className="td_fs_24 td_semibold td_mb_2">Marvin McKinney</h3>
                                                                <p className="td_fs_14 mb-0 td_heading_color td_opacity_7">15th Batch Students</p>
                                                            </div>
                                                        </div>
                                                        <span className="td_quote_icon td_accent_color">
                                                            <svg width="65" height="46" viewBox="0 0 65 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4.64286 46H18.5714L27.8571 27.6V0H0V27.6H13.9286L4.64286 46ZM41.7857 46H55.7143L65 27.6V0H37.1429V27.6H51.0714L41.7857 46Z" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="td_slide">
                                                    <div className="td_testimonial td_style_1 td_type_3">
                                                        <div className="td_rating td_mb_35" data-rating="5">
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-regular fa-star"></i>
                                                            <div className="td_rating_percentage">
                                                                <i className="fa-solid fa-star fa-fw"></i>
                                                                <i className="fa-solid fa-star fa-fw"></i>
                                                                <i className="fa-solid fa-star fa-fw"></i>
                                                                <i className="fa-solid fa-star fa-fw"></i>
                                                                <i className="fa-solid fa-star fa-fw"></i>
                                                            </div>
                                                        </div>
                                                        <blockquote className="td_testimonial_text td_fs_24 td_medium td_heading_color td_mb_35 td_opacity_9">
                                                            The pandemic has accelerated the shift to online and hybrid learning models. Platforms like Coursera, edX, and university-specific online programs, accelerated the shift to online and hybrid.
                                                        </blockquote>
                                                        <div className="td_testimonial_meta">
                                                            <img src="assets/img/home_4/avatar_1.png" alt="" />
                                                            <div className="td_testimonial_meta_right">
                                                                <h3 className="td_fs_24 td_semibold td_mb_2">Marvin McKinney</h3>
                                                                <p className="td_fs_14 mb-0 td_heading_color td_opacity_7">15th Batch Students</p>
                                                            </div>
                                                        </div>
                                                        <span className="td_quote_icon td_accent_color">
                                                            <svg width="65" height="46" viewBox="0 0 65 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4.64286 46H18.5714L27.8571 27.6V0H0V27.6H13.9286L4.64286 46ZM41.7857 46H55.7143L65 27.6V0H37.1429V27.6H51.0714L41.7857 46Z" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="td_slider_arrows td_style_2">
                                <div className="td_left_arrow rounded-circle td_center td_white_color">
                                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.99995 1C6.99995 1 1.00001 5.41893 1 7.00005C0.999987 8.58116 7 13 7 13" stroke="#0F121C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div className="td_right_arrow rounded-circle td_center td_white_color">
                                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.00005 1C1.00005 1 6.99999 5.41893 7 7.00005C7.00001 8.58116 1 13 1 13" stroke="#0F121C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="td_height_120 td_height_lg_80"></div>
                    </div>
                </div>
            </section>

            <section>
                <div className="td_height_112 td_height_lg_75"></div>
                <div className="container">
                    <div className="td_section_heading td_style_1 text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                        <p className="td_section_subtitle_up td_fs_18 td_semibold td_spacing_1 td_mb_10 text-uppercase td_accent_color">WE CHANGE YOUR LIFE & WORLD</p>
                        <h2 className="td_section_title td_fs_48 mb-0">We’re Dedicated To Excellent <br />Service And Kids Care</h2>
                    </div>
                    <div className="td_height_50 td_height_lg_50"></div>
                    <div className="row td_gap_y_24">
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                            <div className="td_team td_style_3 text-center position-relative">
                                <div className="td_team_thumb_wrap td_mb_20">
                                    <div className="td_team_thumb">
                                        <img src="assets/img/home_4/team_member_5.jpg" alt="" className="w-100 td_radius_10" />
                                    </div>
                                    <img className="td_team_thumb_shape" src="assets/img/home_4/team_shape.png" alt="" />
                                </div>
                                <div className="td_team_info td_white_bg">
                                    <h3 className="td_team_member_title td_fs_24 td_semibold mb-0">Oshana Alexa</h3>
                                    <p className="td_team_member_designation mb-0 td_fs_18 td_opacity_7 td_heading_color">English teacher</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.35s">
                            <div className="td_team td_style_3 text-center position-relative">
                                <div className="td_team_thumb_wrap td_mb_20">
                                    <div className="td_team_thumb">
                                        <img src="assets/img/home_4/team_member_2.jpg" alt="" className="w-100 td_radius_10" />
                                    </div>
                                    <img className="td_team_thumb_shape" src="assets/img/home_4/team_shape.png" alt="" />
                                </div>
                                <div className="td_team_info td_white_bg">
                                    <h3 className="td_team_member_title td_fs_24 td_semibold mb-0">Yohana Alexa</h3>
                                    <p className="td_team_member_designation mb-0 td_fs_18 td_opacity_7 td_heading_color">English teacher</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
                            <div className="td_team td_style_3 text-center position-relative">
                                <div className="td_team_thumb_wrap td_mb_20">
                                    <div className="td_team_thumb">
                                        <img src="assets/img/home_4/team_member_6.jpg" alt="" className="w-100 td_radius_10" />
                                    </div>
                                    <img className="td_team_thumb_shape" src="assets/img/home_4/team_shape.png" alt="" />
                                </div>
                                <div className="td_team_info td_white_bg">
                                    <h3 className="td_team_member_title td_fs_24 td_semibold mb-0">Mr. Lucas Johans</h3>
                                    <p className="td_team_member_designation mb-0 td_fs_18 td_opacity_7 td_heading_color">English teacher</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.45s">
                            <div className="td_team td_style_3 text-center position-relative">
                                <div className="td_team_thumb_wrap td_mb_20">
                                    <div className="td_team_thumb">
                                        <img src="assets/img/home_4/team_member_4.jpg" alt="" className="w-100 td_radius_10" />
                                    </div>
                                    <img className="td_team_thumb_shape" src="assets/img/home_4/team_shape.png" alt="" />
                                </div>
                                <div className="td_team_info td_white_bg">
                                    <h3 className="td_team_member_title td_fs_24 td_semibold mb-0">Mary D Kuaesa</h3>
                                    <p className="td_team_member_designation mb-0 td_fs_18 td_opacity_7 td_heading_color">English teacher</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="td_height_50 td_height_lg_50"></div>
                    <div className="td_team_3_footer text_center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                        <b className="td_fs_18 td_normal td_fs_18 td_heading_color">Our Valuable Expert Teachers Team</b>
                        <a href="#" className="td_btn td_style_1 td_radius_30 td_medium">
                            <span className="td_btn_in td_white_color td_accent_bg">
                                <span>See All Team</span>
                                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.1575 4.34302L3.84375 15.6567" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M15.157 11.4142C15.157 11.4142 16.0887 5.2748 15.157 4.34311C14.2253 3.41142 8.08594 4.34314 8.08594 4.34314" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
                <div className="td_height_120 td_height_lg_80"></div>
            </section>

            <footer className="td_footer td_style_1 td_type_1 td_color_1">
                <div className="container">
                    <div className="td_footer_row">
                        <div className="td_footer_col">
                            <div className="td_footer_widget">
                                <div className="td_footer_text_widget td_fs_18">
                                    <img src="assets/img/home_4/logo/young-leaners.png" alt="Logo" width={150} height={200} />
                                    <p>Far far away, behind the word mountains, far from the Consonantia, there live the blind texts.</p>
                                </div>
                                <div className="td_footer_social_btns td_fs_20">
                                    <a href="#" className="td_center">
                                        <i className="fa-brands fa-facebook-f"></i>
                                    </a>
                                    <a href="#" className="td_center">
                                        <i className="fa-brands fa-x-twitter"></i>
                                    </a>
                                    <a href="#" className="td_center">
                                        <i className="fa-brands fa-instagram"></i>
                                    </a>
                                    <a href="#" className="td_center">
                                        <i className="fa-brands fa-pinterest-p"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="td_footer_col">
                            <div className="td_footer_widget">
                                <h2 className="td_footer_widget_title td_fs_32 td_white_color td_medium td_mb_30">Navigate</h2>
                                <ul className="td_footer_widget_menu">
                                    <li><a href="#">Home</a></li>
                                    <li><a href="#">About</a></li>
                                    <li><a href="#">Contact</a></li>
                                    <li><a href="#">Refund</a></li>
                                    <li><a href="#">Help Center</a></li>
                                    <li><a href="#">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="td_footer_col">
                            <div className="td_footer_widget">
                                <h2 className="td_footer_widget_title td_fs_32 td_white_color td_medium td_mb_30">Courses</h2>
                                <ul className="td_footer_widget_menu">
                                    <li><a href="#">Business Coach</a></li>
                                    <li><a href="#">Development Coach</a></li>
                                    <li><a href="#">Testimonials</a></li>
                                    <li><a href="#">Seo Optimization</a></li>
                                    <li><a href="#">Web design</a></li>
                                    <li><a href="#">Life Coach</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="td_footer_col">
                            <div className="td_footer_widget">
                                <h2 className="td_footer_widget_title td_fs_32 td_white_color td_medium td_mb_30">Subscribe Now</h2>
                                <ul className="td_footer_address_widget td_medium td_mp_0">
                                    <li><i className="fa-solid fa-phone-volume"></i><a href="cal:+23(000)68603">+23 (000) 68 603</a></li>
                                    <li><i className="fa-solid fa-envelope"></i><a href="mailto:help@educve.kinder.com">help@educve.kinder.com</a></li>
                                    <li><i className="fa-solid fa-location-dot"></i>66 broklyn golden street <br />600 New york. USA</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="td_footer_bottom td_fs_18">
                    <div className="container">
                        <div className="td_footer_bottom_in">
                            <p className="td_copyright mb-0">Copyright 2024 ©educve | All Right Reserved</p>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="td_scrollup">
                <i className="fa-solid fa-arrow-up"></i>
            </div>
        </>
    )
}

export default Index